<script>
import TablePage from '../../../../../../components/table_page';
import { Form, DetailLog, AdjustLog } from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
    DetailLog,
    AdjustLog,
  },
  data() {
    return {
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    this.searchFormData.isTest = '0';
    await this.getConfigList('dms_rebate_detail_table');
    this.formData.isTest = '0';
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'adjustment') {
        this.modalConfig.title = '调整';
        this.formConfig.selection = [row];
        this.modalConfig.showFooter = false;
        this.openModal();
      } else if (val.code === 'rebate_calculation_log') {
        this.modalConfig.title = '返利计算日志';
        this.propsObjInData = row;
        // this.formConfig.isTest = '1';
        this.formName = 'DetailLog';
        this.openFull();
      } else if (val.code === 'log') {
        this.modalConfig.title = '调整日志';
        this.propsObjInData = row;
        // this.formConfig.isTest = '1';
        this.formName = 'AdjustLog';
        this.openFull();
      }
      return true;
    },
  },
};
</script>
