<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
  },
  async mounted() {
    this.rule = await this.getFormRule('dms_rebate_detail_adjust');
    const actualAmount = this.formConfig.selection[0].actualAmount || 0;

    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'cusName' || rowData.field === 'cusCode' || rowData.field === 'computeAmount' || rowData.field === 'actualAmount') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'adjustAmount') {
        rowData.on = {
          ...rowData.on,
          input: (val) => {
            this.setValue({
              actualAmount: this.accAdd(val || 0, actualAmount),
            });
          },
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
  },
  methods: {
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 获取表单数据
    getData() {
      const data = JSON.parse(JSON.stringify(this.formConfig.selection[0]));
      data.adjustAmount = 0;
      data.rebateDetailCode = data.code;
      this.setValue(data);
    },

    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
      };
      const path = '/rebate/m/rebateDetail/adjust';
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
  },
};
</script>
